import React from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { Github, Star, GitBranch, GitPullRequest,Users,TrendingUp } from 'lucide-react';
import { LineChart, Line, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

// Types for better code organization
type ChartData = { name: string; value: number; }[];
type Feature = {
  icon: React.ReactNode;
  title: string;
  description: string;
  stats: string;
  chartData?: ChartData;
  imageUrl?: string;
  imagePosition?: 'left' | 'right';
};

// Constants
const FEATURES: Feature[] = [
  {
    icon: <Star size={24} />,
    title: "Compras Grupales Inteligentes",
    description: "Únete a grupos de compra para obtener mejores precios en tus productos favoritos",
    stats: "100K+ usuarios activos",
    imageUrl: "https://th.bing.com/th/id/R.a17a4654e0ba4e26a6f2a589499e2477?rik=TV10d7mT0PYonw&pid=ImgRaw&r=0",
    imagePosition: 'right',
    chartData: Array(6).fill(null).map((_, i) => ({
      name: ["Electrónica", "Moda", "Hogar", "Deportes", "Belleza", "Alimentos"][i],
      value: Math.floor(Math.random() * 300) + 100
    }))
  },
  {
    icon: <Users size={24} />,
    title: "Comunidad de Compradores",
    description: "Conecta con otros usuarios y únete a pulls para maximizar tu ahorro",
    stats: "50K+ pulls activos",
    imageUrl: "https://th.bing.com/th/id/R.a17a4654e0ba4e26a6f2a589499e2477?rik=TV10d7mT0PYonw&pid=ImgRaw&r=0",
    imagePosition: 'left',
    chartData: Array(6).fill(null).map((_, i) => ({
      name: ["Ene", "Feb", "Mar", "Abr", "May", "Jun"][i],
      value: Math.floor(Math.random() * 1000) + 500
    }))
  },
  {
    icon: <TrendingUp size={24} />,
    title: "Ahorros Progresivos",
    description: "Observa cómo bajan los precios a medida que más personas se unen a un pull",
    stats: "35% de ahorro promedio",
    imageUrl: "https://th.bing.com/th/id/R.a17a4654e0ba4e26a6f2a589499e2477?rik=TV10d7mT0PYonw&pid=ImgRaw&r=0",
    imagePosition: 'right',
    chartData: Array(6).fill(null).map((_, i) => ({
      name: ["10", "20", "30", "40", "50", "60+"][i],
      value: 100 - (Math.floor(Math.random() * 5) + 1) * (i + 1)
    }))
  }
];

const DataVisual = ({ feature }: { feature: Feature }) => {
  if (feature.imageUrl) {
    return (
      <img 
        src={feature.imageUrl} 
        alt={feature.title}
        className="rounded-lg object-cover w-full h-full"
      />
    );
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart data={feature.chartData}>
        <YAxis stroke="#393E46" />
        <Tooltip 
          contentStyle={{ 
            background: '#0d1117',
            border: 'none',
            borderRadius: '6px',
            boxShadow: '0 0 20px rgba(0,173,181,0.1)'
          }}
          itemStyle={{ color: '#00ADB5' }}
        />
        <Line 
          type="monotone" 
          dataKey="value" 
          stroke="#00ADB5" 
          strokeWidth={2}
          dot={{ fill: '#00ADB5' }}
          activeDot={{ 
            fill: '#00ADB5',
            stroke: '#0d1117',
            strokeWidth: 2,
            r: 6
          }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const FeatureCard = ({ feature, index }: { feature: Feature; index: number }) => {
  const { scrollYProgress } = useScroll();
  const y = useTransform(scrollYProgress, [0, 1], [100 * index, 0]);
  const opacity = useTransform(scrollYProgress, 
    [0.1 * index, 0.2 * index, 0.8 + 0.1 * index, 0.9 + 0.1 * index], 
    [0, 1, 1, 0]
  );

  const contentOrder = feature.imagePosition === 'left' ? 'md:flex-row-reverse' : 'md:flex-row';

  return (
    <motion.div 
      style={{ y, opacity }}
      className="mb-8 min-h-screen flex items-center"
    >
      <div className="bg-[#0d1117] p-4 md:p-8 rounded-lg w-full md:h-[80vh]">
        <div className={`flex flex-col ${contentOrder} gap-8 items-center h-full`}>
          <div className="flex-1 space-y-4 md:space-y-6">
            <motion.div
              className="relative inline-block"
              whileHover={{ scale: 1.1 }}
            >
              {feature.icon}
              <div className="absolute inset-0 bg-[#00ADB5] opacity-20 blur-xl rounded-full" />
            </motion.div>
            
            <h2 className="text-4xl md:text-6xl lg:text-[72px] leading-tight font-Medium bg-gradient-to-r from-[#00ADB5] to-[#EEEEEE] bg-clip-text text-transparent">
              {feature.title}
            </h2>
            <p className="text-base md:text-lg lg:text-[20px] text-[#EEEEEE] opacity-80">
              {feature.description}
            </p>
            <div className="text-lg md:text-xl lg:text-[24px] font-Medium text-[#00ADB5]">
              {feature.stats}
            </div>
          </div>
          
          <div className="flex-1 h-64 md:h-full w-full">
            <DataVisual feature={feature} />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const LandingPage = () => {
  return (
    <div className="min-h-screen px-4 py-16 md:py-24 relative overflow-x-hidden">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-center mb-16 md:mb-32 relative z-10"
      >
        <div className="relative inline-block mb-8">
          <Github className="w-16 h-16 md:w-20 md:h-20 text-[#00ADB5]" />
          <div className="absolute inset-0 bg-[#00ADB5] opacity-20 blur-xl rounded-full" />
        </div>
        <h1 className="text-5xl md:text-7xl lg:text-[96px] font-Medium mb-4 bg-gradient-to-r from-[#00ADB5] to-[#EEEEEE] bg-clip-text text-transparent">
         Compremos juntos
        </h1>
        <p className="text-[#393E46] text-lg md:text-2xl lg:text-[24px] font-medium">
          Donde los descuentos crecen con la comunidad
        </p>
      </motion.div>

      <div className="max-w-6xl mx-auto relative z-10">
        {FEATURES.map((feature, index) => (
          <FeatureCard key={index} feature={feature} index={index} />
        ))}
      </div>
    </div>
  );
};

export default LandingPage;