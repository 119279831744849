import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Search, ShoppingCart, User, Menu, Star, ChevronDown } from 'lucide-react';

const Button = ({ children, className, ...props }) => (
  <button
    className={`px-4 py-2 rounded-full font-semibold transition duration-300 border-2 border-[#00ADB5] text-[#00ADB5] hover:bg-[#00ADB5] hover:text-[#222831] ${className}`}
    {...props}
  >
    {children}
  </button>
);

const Section = ({ title, children, className }) => (
  <section className={`container mx-auto py-12 ${className}`}>
    <h2 className="text-3xl font-bold mb-8 text-[#EEEEEE]">{title}</h2>
    {children}
  </section>
);

const ProductCard = ({ title, description, price }) => (
  <motion.div 
    whileHover={{ scale: 1.03 }}
    className="bg-[#393E46] p-6 rounded-lg shadow-lg"
  >
    <div className="bg-[#222831] h-48 rounded-lg mb-4"></div>
    <h3 className="text-xl font-semibold mb-2 text-[#EEEEEE]">{title}</h3>
    <p className="text-[#EEEEEE] mb-4">{description}</p>
    <div className="flex justify-between items-center">
      <span className="text-2xl font-bold text-[#EEEEEE]">${price}</span>
      <Button>Add to Cart</Button>
    </div>
  </motion.div>
);

const Review = ({ name, rating, comment }) => (
  <div className="bg-[#393E46] p-6 rounded-lg">
    <div className="flex items-center mb-4">
      <div className="w-12 h-12 bg-[#222831] rounded-full mr-4"></div>
      <div>
        <h4 className="font-semibold text-[#EEEEEE]">{name}</h4>
        <div className="flex">
          {[...Array(5)].map((_, i) => (
            <Star key={i} className={`w-4 h-4 ${i < rating ? 'text-[#00ADB5]' : 'text-[#222831]'}`} />
          ))}
        </div>
      </div>
    </div>
    <p className="text-[#EEEEEE]">{comment}</p>
  </div>
);

const FAQ = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="border-b border-[#393E46]">
      <button
        className="flex justify-between items-center w-full py-4 text-left text-[#EEEEEE]"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-semibold">{question}</span>
        <ChevronDown className={`w-5 h-5 transition-transform ${isOpen ? 'transform rotate-180' : ''}`} />
      </button>
      {isOpen && <p className="pb-4 text-[#EEEEEE]">{answer}</p>}
    </div>
  );
};

const LandingPage = () => {
  return (
    <div className="min-h-screen bg-[#222831] text-[#EEEEEE] font-['IBM Plex Sans']">
      <main>
        <motion.section 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="relative h-[70vh] bg-gradient-to-r from-[#222831] to-[#393E46] flex items-center justify-center text-center px-4"
        >
          <div>
            <h2 className="text-5xl md:text-7xl font-bold mb-4 text-[#00ADB5]">Tech Meets Style</h2>
            <p className="text-xl md:text-2xl mb-8 max-w-2xl mx-auto">Discover the perfect blend of cutting-edge technology and trendsetting fashion at DropHack</p>
            <Button className="text-lg">
              Shop Now
            </Button>
          </div>
        </motion.section>

        <Section title="Featured Products">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <ProductCard title="Pro Gaming Laptop" description="Dominate the competition with our latest gaming powerhouse." price={1299.99} />
            <ProductCard title="Wireless Earbuds" description="Crystal clear audio with noise-cancelling technology." price={149.99} />
            <ProductCard title="Smart Running Shoes" description="Track your performance with every step." price={199.99} />
          </div>
        </Section>

        <Section title="Customer Reviews" className="bg-[#393E46]">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <Review name="Alex Johnson" rating={5} comment="The gaming laptop exceeded my expectations. Smooth gameplay and stunning graphics!" />
            <Review name="Sarah Lee" rating={4} comment="Love the wireless earbuds! Great sound quality, though battery life could be better." />
            <Review name="Mike Chen" rating={5} comment="These smart running shoes have revolutionized my training. The data insights are invaluable!" />
          </div>
        </Section>

        <Section title="Frequently Asked Questions">
          <div className="space-y-4">
            <FAQ 
              question="What is your return policy?"
              answer="We offer a 30-day return policy for all products. Items must be in their original condition with all accessories included."
            />
            <FAQ 
              question="How long does shipping take?"
              answer="Standard shipping typically takes 3-5 business days. Express shipping options are available at checkout for faster delivery."
            />
            <FAQ 
              question="Do you offer international shipping?"
              answer="Yes, we ship to most countries worldwide. Shipping costs and delivery times vary depending on the destination."
            />
          </div>
        </Section>
      </main>

      <footer className="bg-[#393E46] py-12">
        <div className="container mx-auto px-4 grid grid-cols-2 md:grid-cols-4 gap-8">
          {['Customer Service', 'About Us', 'Popular Categories', 'Connect With Us'].map((title) => (
            <div key={title}>
              <h5 className="font-bold mb-4 text-[#EEEEEE]">{title}</h5>
              <ul className="space-y-2 text-sm text-[#EEEEEE]">
                {['Link 1', 'Link 2', 'Link 3'].map((link) => (
                  <li key={link} className="hover:text-[#00ADB5] cursor-pointer">{link}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;