import React, { useState, useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext, PaymentContext } from '../App';
import KRGlue from '@lyracom/embedded-form-glue';


const MisPedidos = () => {
  const [pedidos, setPedidos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedLines, setSelectedLines] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [formToken, setFormToken] = useState(null);
  const navigate = useNavigate();
  const { checkAuthStatus } = useContext(AuthContext);
  const { publicKey, endPoint, getFormToken, validatePayment } =  useContext(PaymentContext);
  const [paymentError, setPaymentError] = useState(null);
  const [pullStates, setPullStates] = useState({});


  const estadoColores = {


    registered: 'text-[#393E46] border-[#393E46]',
    paid: 'text-[#00ADB5] border-[#00ADB5]',

  };
  const fetchPullState = async (pullId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/pulls-detail/${pullId}/`);
      setPullStates(prev => ({
        ...prev,
        [pullId]: response.data.state
      }));
    } catch (error) {
      console.error('Error fetching pull state:', error);
    }
  };

  const updateSalesOrderLines = useCallback(async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/update-salesorderlines/`);
      console.log('SalesOrderLines actualizadas correctamente');
    } catch (error) {
      console.error('Error al actualizar SalesOrderLines:', error);
    }
  }, []);

  const cargarPedidos = useCallback(async (page) => {
    try {
      setLoading(true);
      await updateSalesOrderLines(); // Actualizar SalesOrderLines antes de cargar los pedidos

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/sales-orders/?page=${page}&state=sale,done,cancel`);
      setPedidos(response.data.results);
      setTotalPages(Math.ceil(response.data.count / 10));
      setError(null);
    } catch (error) {
      console.error('Error al cargar los pedidos:', error);
      if (error.response?.status === 401) {
        checkAuthStatus();
      } else {
        setError("Error al cargar los pedidos");
      }
    } finally {
      setLoading(false);
    }
  }, [checkAuthStatus]);

  useEffect(() => {
    cargarPedidos(currentPage);
  }, [currentPage, cargarPedidos]);

  useEffect(() => {
    pedidos.forEach(pedido => {
      pedido.salesorderline_set.forEach(item => {
        if (item.pull) {
          fetchPullState(item.pull);
        }
      });
    });
  }, [pedidos]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date) 
      ? date.toLocaleString('es-ES', { 
          year: 'numeric', 
          month: 'long', 
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })
      : 'Fecha no válida';
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleLineSelection = (line) => {
    const isSelected = selectedLines.some(l => l.id_salesorderline === line.id_salesorderline);
    let newSelectedLines;
    if (isSelected) {
      newSelectedLines = selectedLines.filter(l => l.id_salesorderline !== line.id_salesorderline);
    } else {
      newSelectedLines = [...selectedLines, line];
    }
    setSelectedLines(newSelectedLines);
    calculateTotalAmount(newSelectedLines);
  };

  const calculateTotalAmount = (selectedLines) => {
    const total = selectedLines.reduce((sum, line) => sum + parseFloat(line.price_subtotal), 0);
    setTotalAmount(total);
  };

  const handleClosePaymentModal = () => {
    setShowPaymentForm(false);
    window.location.reload();
  };

  const handlePayment = async () => {
    try {
      const orderLines = selectedLines.map(line => ({
        salesorderline_id: line.id_salesorderline,
        amount: parseFloat(line.price_subtotal)
      }));
      
      const token = await getFormToken(totalAmount, orderLines);
      setFormToken(token);
      setShowPaymentForm(true);
      setPaymentError(null);

      const { KR } = await KRGlue.loadLibrary(endPoint, publicKey);
      await KR.setFormConfig({
        formToken: token,
        "kr-language": "es-ES"
      });

      await KR.onSubmit(async (paymentData) => {
        try {
          const isPaid = await validatePayment(paymentData);
          if (isPaid) {
            alert("Pago realizado con éxito");
            window.location.reload();
          } else {
            setPaymentError("El pago no se completó correctamente");
            window.location.reload();
          }
        } catch (error) {
          console.error('Error al procesar el pago:', error);
          setPaymentError("Error al procesar el pago: " + (error.response?.data?.error || error.message || 'Error desconocido'));
          window.location.reload();
        }
      });

      await KR.renderElements("#myPaymentForm");
      await KR.showForm(token);
    } catch (error) {
      console.error('Error al iniciar el proceso de pago:', error);
      setPaymentError('Error al iniciar el proceso de pago: ' + (error.message || 'Error desconocido'));
      window.location.reload();
    }
  };

  if (loading) return <div className="text-center mt-10">Loading...</div>;
  if (error) return <div className="text-center mt-10">{error}</div>;
  if (pedidos.length === 0) return <div className="text-center mt-10">No tienes pedidos confirmados</div>;

  return (
    <div className="page-container">
      <h2 className="page-title">Mis Pedidos</h2>
      
      {pedidos.map((pedido) => (
        <div key={pedido.id_salesorder} className="card mb-4">
          <h3 className="text-center mb-4">Orden: {pedido.name}</h3>
          <p className="text-sm text-gray-400 mb-2">Fecha: {formatDate(pedido.date_order)}</p>
          
          {pedido.salesorderline_set.map((item) => (
            <div key={item.id_salesorderline} className="item">
              <div className="flex items-center flex-1 min-w-0">
                <input
                  type="checkbox"
                  checked={selectedLines.some(l => l.id_salesorderline === item.id_salesorderline)}
                  onChange={() => handleLineSelection(item)}
                  disabled={!(item.pull && pullStates[item.pull] === 'close' && item.state === 'registered')}
                  className="mr-2"
                />
                <div className="item-name" title={item.product_name}>
                  {item.product_name}
                </div>
              </div>
              
              <div className="flex items-center justify-between mt-1">
                <span className="px-2 py-1 rounded-full text-xs font-semibold">Cantidad: {parseInt(item.product_qty)}</span>
                <span className="item-subtotal">
                  S/{parseFloat(item.price_subtotal).toFixed(2)}
                </span>
              </div>

              <div className="flex items-center justify-between mt-1">
                <div className={`px-2 py-1 rounded-full text-xs font-semibold ${estadoColores[item.state]}`}>
                  {item.state}
                </div>
                {item.pull && (
                  <div className={`px-2 py-1 rounded-full text-xs font-semibold ${estadoColores[pullStates[item.pull]]}`}>
                    Pull: {pullStates[item.pull]}
                  </div>
                )}
              </div>
            </div>
          ))}

          <p className="total">
            Total: S/{parseFloat(pedido.amount_total).toFixed(2)}
          </p>
        </div>
      ))}

      <div className="pagination">
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={`btn mx-1 ${currentPage === page ? 'bg-primary-color' : ''}`}
          >
            {page}
          </button>
        ))}
      </div>

      {selectedLines.length > 0 && (
        <div className="confirm-container">
          <h3 className="text-lg font-semibold mb-2 text-center">Total a pagar: S/{totalAmount.toFixed(2)}</h3>
          <button
            onClick={handlePayment}
            className="btn confirm-btn"
          >
            Pagar líneas seleccionadas
          </button>
        </div>
      )}

      {showPaymentForm && formToken && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-card-bg-color p-6 rounded-lg max-w-md w-full relative">
            <button 
              onClick={handleClosePaymentModal}
              className="absolute top-2 right-2 text-gray-400 hover:text-gray-200"
            >
              ✕
            </button>
            <h3 className="text-lg font-semibold mb-4">Formulario de Pago</h3>
            <div id="myPaymentForm">
              <div className="kr-embedded"></div>
            </div>
          </div>
        </div>
      )}
      
      {paymentError && (
        <div className="error-message mt-4 text-red-500">
          {paymentError}
        </div>
      )}
    </div>
  );
};

export default MisPedidos;